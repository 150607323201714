.p-datepicker table td {
  span {
    margin: 1px;
  }
}

.p-datepicker-header {
  padding-bottom: 8px;
}

.p-datepicker-buttonbar {
  margin-top: 6px;
  padding-top: 4px;
}

.p-calendar.p-calendar-w-btn {
  border-right: 1px solid var(--bs-border-color);
  border-radius: 20px;
}
